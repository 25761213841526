import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import { roundIcon } from '../../../../shared/round-icon.css'

import editIcon from '../../../../../../../images/pencil.svg'

import styles from '../payment.css'

const EditIcon = ({ onClick }) => {
  return (
    <div
      className={styles.editButton}
      onClick={onClick}
    >
      <ReactSVG
        src={editIcon}
        beforeInjection={(svg) => {
          svg.setAttribute('class', roundIcon)
        }}
      />
    </div>
  )
}

EditIcon.propTypes = {
  onClick: PropTypes.func
}

EditIcon.defaultProps = {
  onClick: () => {}
}

export default EditIcon
