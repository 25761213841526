import React from 'react'
import ReactSVG from 'react-svg'

import iconStyles from '../../../../shared/round-icon.css'
import styles from '../subscription-plan.css'

export default function renderButton(onClick, key, buttonStyle, text, icon) {
  return (
    <button
      className={styles.buttonContainer}
      onClick={onClick}
      key={key}
      type="submit"
    >
      <div
        className={buttonStyle}
        data-lbx-e2e={text}
      >
        {text}
        {icon && (
          <ReactSVG
            src={icon}
            beforeInjection={svg => {
              svg.setAttribute('class', iconStyles.roundIcon)
            }}
          />
        )}
      </div>
    </button>
  )
}
