import classNames from 'classnames'
import React from 'react'
import styles from '../subscription-plan.css'

const SuspendedPlan = () => {
  return (
    <div className={classNames(styles.container, styles.noBorder)}>
      <h2 className={styles.header}>Your plan has been suspended</h2>
      <p className={styles.planDescription}>
        Please add a valid credit card in the Payment Details section below to continue your subscription.
      </p>
    </div>
  )
}

export default SuspendedPlan
