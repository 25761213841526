import {
  compose,
  withStateHandlers,
  withHandlers,
  withProps,
  lifecycle,
  branch,
  renderComponent
} from 'recompose'
import { withApollo, graphql } from 'react-apollo'
import classNames from 'classnames'
import {
  pathOr,
  pathEq,
  omit,
  equals,
  complement,
  pipe,
  nthArg,
  and,
  is,
  prepend,
  dropWhile,
  contains,
  isEmpty,
  cond,
  allPass,
  T,
  identity,
  gte,
  length,
  propEq,
  symmetricDifference
} from 'ramda'

import {
  getTableColumns,
  LoadingTable
} from '../../../../../components/settings/my-account/subscription-and-payment/payment-details/payment-table-components'
import MySavedCardsTable from '../../../../../components/settings/my-account/subscription-and-payment/payment-details/my-saved-cards-table'

import {
  withDeleteCreditCardMutation,
  omitProps,
  withShowModalClick
} from '../../../../../hoc'
import { withSetCreditcardDefaultMutationAndLoading } from '../../../../../hoc/with-set-creditcard-default-mutation'
import { checkQueryStatus } from '../../../../../lib/utils'
import {
  MY_ACCOUNT,
  MODALS,
  SUBSCRIPTION_STATUS
} from '../../../../../constants'

import styles from '../../../../../components/settings/my-account/subscription-and-payment/payment-details/payment.css'

import ACCOUNT_CREDIT_CARDS_QUERY from '../../../../../../graphql/queries/account-credit-cards.gql'
import CREDIT_CARD_ADD_QUERY from '../../../../../../graphql/queries/creditcard-add.gql'
import CREDIT_CARD_UPDATE_QUERY from '../../../../../../graphql/queries/creditcard-update.gql'

// Credit Card queries HoC for prefetch queries
const withCreditCardIFrameQueries = compose(
  withApollo,
  withHandlers({
    makeCreditCardAddQuery: ({ client }) => () => {
      return client.query({
        query: CREDIT_CARD_ADD_QUERY
      })
    },
    makeCreditCardUpdateQuery: ({ client }) => id => {
      return client.query({
        query: CREDIT_CARD_UPDATE_QUERY,
        variables: { id }
      })
    }
  }),
  omitProps(['client'])
)

const getCreditCardAddResponse = pathOr('', ['data', 'creditcardAdd'])

const getCreditCardUpdateResponse = pathOr(
  { paymentMethodId: '', iframe: '' },
  ['data', 'creditcardUpdate']
)

const checkCardLimit = pipe(
  dropWhile(isEmpty),
  length,
  len => gte(len, MY_ACCOUNT.CREDIT_CARD_LIMIT)
)

const checkTableData = pipe(
  symmetricDifference,
  dropWhile(isEmpty),
  length
)

// Handles row placeholder used for indexing
// IFrame Sub Comp for Add / Edit iframes.
const isAddNewIframe = pipe(
  nthArg(1),
  and(is(Boolean), equals(true))
)
const isNotAddNewIframe = complement(isAddNewIframe)

const prependEmptyTdPlaceholder = prepend({})
const removeEmptyTdPlaceholder = dropWhile(isEmpty)

const hasEmptyPlaceholder = contains({})
const doesNotHaveEmptyPlaceholder = complement(hasEmptyPlaceholder)

/**
 * The table IFrame/Sub Comp is determined by the `cardRowIndex`,
 * a.k.a. the index of the item in the table data array.
 * In order to display the Add CC iframe at the TOP of the table,
 * we need to prepend an empty placeholder, empty {} at 0 index,
 * so that the Add iframe displays above all the other results.
 * Otherwise, the iframe will just take over the row like the Edit CC iframe does.
 */
const handleTableDataForIframes = cond([
  [
    allPass([isAddNewIframe, doesNotHaveEmptyPlaceholder]),
    prependEmptyTdPlaceholder
  ],
  [allPass([isNotAddNewIframe, hasEmptyPlaceholder]), removeEmptyTdPlaceholder],
  [T, identity]
])

const initialCardState = ({ accountCreditCards }) => ({
  activePanel: null,
  isConfirmationModalOpen: false,
  paymentMethodId: '',
  iframeIsOpen: false,
  iframeEditUrl: '',
  iframeAddUrlUrl: '',
  cardRowIndex: 0,
  // used on mobile screens to know the current modified credit card
  activeEditionPanel: null,
  // user on mobile screens to know that is adding a new credit card
  isAddNewCreditCard: false,
  tableData: accountCreditCards
})

const enhance = compose(
  graphql(ACCOUNT_CREDIT_CARDS_QUERY, {
    name: 'accountCreditCardsQuery',
    options: {
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    },
    props: ({ ownProps, accountCreditCardsQuery }) => {
      return {
        ...ownProps,
        accountCreditCardsQuery,
        accountCreditCards: pathOr(
          [],
          ['account', 'creditcards'],
          accountCreditCardsQuery
        )
      }
    }
  }),
  branch(
    ({ accountCreditCardsQuery }) => checkQueryStatus(accountCreditCardsQuery),
    renderComponent(LoadingTable)
  ),
  withStateHandlers(initialCardState, {
    setActivePanel: () => creditCardID => {
      return {
        activePanel: creditCardID,
        activeEditionPanel: null,
        isAddNewCreditCard: false,
        iframeIsOpen: false
      }
    },
    setDisplayConfirmationModal: () => creditCardID => {
      return {
        isConfirmationModalOpen: Boolean(creditCardID),
        paymentMethodId: creditCardID || ''
      }
    },
    setCreditCardAddDetails: () => iframeAddUrl => {
      return { iframeAddUrl }
    },
    setCreditCardUpdateDetails: () => ({ paymentMethodId, iframe }) => {
      return {
        paymentMethodId,
        iframeEditUrl: iframe
      }
    },
    setDisplayCreditCardIframe: ({ tableData }) => (
      creditCardID,
      cardRowIndex,
      isAddNew,
      matches
    ) => {
      return {
        iframeIsOpen: Boolean(creditCardID),
        activePanel: isAddNew ? null : creditCardID,
        activeEditionPanel: isAddNew ? null : creditCardID,
        isAddNewCreditCard: isAddNew,
        cardRowIndex: cardRowIndex || 0,
        tableData: matches
          ? handleTableDataForIframes(tableData, isAddNew)
          : tableData
      }
    },
    updateTableData: () => tableData => {
      return { tableData }
    }
  }),
  withShowModalClick,
  withCreditCardIFrameQueries,
  withDeleteCreditCardMutation,
  withSetCreditcardDefaultMutationAndLoading,
  lifecycle({
    UNSAFE_componentWillReceiveProps({ tableData, accountCreditCards, matches }) {
      if (checkTableData(tableData, accountCreditCards)) {
        this.props.updateTableData(accountCreditCards)
      }

      // Only reset the currently active panel whenever
      // the window is resized with a panel open
      if (
        this.props.matches !== matches && // resize window
        (!matches && this.props.activePanel) // mobile screen
      ) {
        this.props.setActivePanel(null)
      }
    }
  }),
  withProps(({ tableData }) => {
    return {
      hasReachedLimit: checkCardLimit(tableData)
    }
  }),
  withHandlers({
    prefetchCreditCardAddResponse: ({
      iframeIsOpen,
      makeCreditCardAddQuery,
      setCreditCardAddDetails,
      hasReachedLimit
    }) => () => {
      if (iframeIsOpen || hasReachedLimit) return
      makeCreditCardAddQuery().then(response => {
        const creditcardAddIframeUrl = getCreditCardAddResponse(response)
        setCreditCardAddDetails(creditcardAddIframeUrl)
      })
    },
    handleRepairSubscription: ({ showModal }) => () => {
      showModal(MODALS.qsParams.updatePaymentDetails)
    },
    prefetchCreditCardUpdateResponse: ({
      iframeIsOpen,
      paymentMethodId,
      makeCreditCardUpdateQuery,
      setCreditCardUpdateDetails
    }) => creditCardID => {
      if (iframeIsOpen || creditCardID === paymentMethodId) return
      makeCreditCardUpdateQuery(creditCardID).then(response => {
        const creditcardUpdate = getCreditCardUpdateResponse(response)
        setCreditCardUpdateDetails(creditcardUpdate)
      })
    }
  }),
  withHandlers({
    // this handler is called by the Collapse component each time the user
    // expands an accordion. It set the current accordion as active and fetch
    // the credit card data for future editions
    prefetchCreditCardOnActivePanel: ({
      iframeEditUrl,
      paymentMethodId,
      setActivePanel,
      prefetchCreditCardUpdateResponse
    }) => creditCardID => {
      // creditCard has value when expands an accordion
      // and undefined when closes it
      setActivePanel(creditCardID)
      // if creditCardID === paymentMethodId the iframeEditUrl values has not changed
      if (
        creditCardID &&
        (!iframeEditUrl || creditCardID !== paymentMethodId)
      ) {
        prefetchCreditCardUpdateResponse(creditCardID)
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      // on mobile screens, prefetch the add iframe
      if (!this.props.matches) {
        this.props.prefetchCreditCardAddResponse()
      }
    }
  }),
  withHandlers({
    // Delete Credit Card / Confirmation Modal
    // ------------------------------------------------------
    handleDeleteCardModalCancelClick: ({
      setDisplayConfirmationModal
    }) => () => {
      setDisplayConfirmationModal(false)
    },
    handleDeleteCardModalConfirmClick: ({
      setDisplayConfirmationModal,
      deleteCreditCard,
      paymentMethodId
    }) => () => {
      deleteCreditCard(paymentMethodId).then(() => {
        setDisplayConfirmationModal(false)
      })
    },
    handleRemoveCreditCard: ({
      setDisplayConfirmationModal
    }) => creditCardID => {
      setDisplayConfirmationModal(creditCardID)
    },
    // Credit Card Add/Update Iframe / Iframe Handlers
    // ------------------------------------------------------
    handleAddCreditCard: ({
      iframeIsOpen,
      setDisplayCreditCardIframe,
      iframeAddUrl,
      hasReachedLimit,
      activeSubscription,
      handleRepairSubscription,
      matches
    }) => () => {
      if (iframeIsOpen || !iframeAddUrl || hasReachedLimit) return
      if (
        pathEq(['status'], SUBSCRIPTION_STATUS.SUSPENDED, activeSubscription)
      ) {
        handleRepairSubscription()
        return
      }
      setDisplayCreditCardIframe(true, 0, true, matches)
    },
    handleEditCreditCard: ({
      iframeIsOpen,
      setDisplayCreditCardIframe,
      matches,
      iframeEditUrl
    }) => (creditCardID, cardRowIndex) => {
      if (iframeIsOpen || !iframeEditUrl) return
      setDisplayCreditCardIframe(creditCardID, cardRowIndex, false, matches)
    },
    handleCheckboxDefaultCard: ({ setCreditcardDefault }) => ({
      column,
      original,
      columnName
    }) => {
      const paymentMethodId = original.id
      const productType = {
        useForPurchases: 'TVOD',
        useForSubscriptions: 'SVOD'
        // use column.id on desktop screens (because we are using the Table component)
        // and columnName value on mobile screens
      }[(column && column.id) || columnName]
      setCreditcardDefault(paymentMethodId, productType)
    },
    onIframeLoad: ({
      setDisplayCreditCardIframe,
      accountCreditCardsQuery
    }) => searchParams => {
      if (isEmpty(searchParams)) return

      if (propEq('status', 'success', searchParams)) {
        setDisplayCreditCardIframe()
        accountCreditCardsQuery.refetch()
      }
    },
    handleIframeCancelClick: ({
      setDisplayCreditCardIframe,
      matches
    }) => () => {
      setDisplayCreditCardIframe(null, null, false, matches)
    },
    // Style Handlers for Table
    // ------------------------------------------------------
    handleTableProps: () => (state, rowInfo, column, instance) => {
      return {
        style: {
          display: instance.props.data.length === 0 && 'none'
        }
      }
    },
    handleTheadProps: () => () => {
      return {
        className: classNames(styles.tableHead, styles.header)
      }
    },
    handleTheadThProps: () => (state, rowInfo, column) => {
      const isCardNicknameCol = column.id === 'nickname'
      const isCardNumberCol = column.id === 'number'
      const isPaymentPlansCol = column.id === 'useForSubscriptions'
      const isRemoveCardCol = column.id === 'remove'
      const isEditCardCol = column.id === 'edit'
      return {
        className: classNames(
          styles.tableHeadTh,
          { [styles.thFirst]: isCardNicknameCol },
          { [styles.flexStart]: isCardNumberCol },
          { [styles.flexStart]: isPaymentPlansCol },
          { [styles.flexEnd]: isRemoveCardCol },
          { [styles.thLast]: isEditCardCol },
          { '-hidden': column.expander }
        )
      }
    },
    handleTdProps: ({
      prefetchCreditCardUpdateResponse,
      iframeIsOpen,
      cardRowIndex
    }) => (state, rowInfo, column) => {
      const isCardNicknameCol = column.id === 'nickname'
      const isCardNumberCol = column.id === 'number'
      const isEditCardCol = column.id === 'edit'

      let tableProps = {
        className: classNames(
          styles.tableData,
          { [styles.tdFirst]: isCardNicknameCol },
          { [styles.flexStart]: isCardNumberCol },
          { [styles.tdLast]: isEditCardCol },
          { '-hidden': column.expander }
        ),
        onMouseOver: () => {
          prefetchCreditCardUpdateResponse(rowInfo.original.id)
        },
        style: {
          display:
            iframeIsOpen && equals(cardRowIndex, rowInfo.viewIndex)
              ? 'none'
              : 'flex'
        }
      }

      if (!isEditCardCol) {
        tableProps = omit(['onMouseOver'], tableProps)
      }

      return tableProps
    },
    handleTrGroupProps: () => () => {
      return {
        // TODO: LBXW-1418: Complete middleware api integration for invalid credit card status
        className: styles.tableRowGroup
      }
    }
  }),
  withProps(
    ({
      handleRemoveCreditCard,
      handleEditCreditCard,
      handleCheckboxDefaultCard,
      matches
    }) => {
      const tableColumnHandlers = {
        handleRemoveCreditCard,
        handleEditCreditCard,
        handleCheckboxDefaultCard
      }
      const tableColumns = getTableColumns(matches, tableColumnHandlers)
      return { tableColumns }
    }
  )
)

export default enhance(MySavedCardsTable)
