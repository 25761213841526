import React from 'react'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'

import {
  ArrowUpIcon,
  ArrowDownIcon
} from '../../subscription-and-payment/payment-details/payment-table-components/credit-card-panel-components'

import styles from './recurring-vouchers.css'

export const VoucherPanelHeader = ({ isOpen, props }) => {
  const voucherType = pathOr('', ['type'], props)

  return (
    <div className={styles.voucherPanelHeader}>
      <span className={styles.voucherPanelHeaderTitle}>{voucherType}</span>
      {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </div>
  )
}

VoucherPanelHeader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  props: PropTypes.shape({
    type: PropTypes.string.isRequired
  }).isRequired
}

export const VoucherPanelBody = ({ columns, vouchersProps, isOpen }) => {
  return (
    <>
      {isOpen &&
        columns.map(col => {
          return (
            <VoucherPanelRow
              key={col.id}
              columnName={col.Header}
              columnCell={col.Cell({
                value: vouchersProps[col.id],
                original: vouchersProps
              })}
            />
          )
        })}
    </>
  )
}

VoucherPanelBody.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.func.isRequired,
      Header: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  vouchersProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired
}

export const VoucherPanelRow = ({ columnName, columnCell }) => {
  return (
    <div className={styles.accordionGroup}>
      <div className={styles.columnName}>{columnName}</div>
      {columnCell}
    </div>
  )
}

VoucherPanelRow.propTypes = {
  columnName: PropTypes.string.isRequired,
  columnCell: PropTypes.element.isRequired
}
