import React from 'react'
import ReactSVG from 'react-svg'

import styles from '../payment.css'

import checkedIcon from '../../../../../../../images/checkbox-checked.svg'

const CheckedIcon = () => {
  return (
    <div className={styles.checkbox}>
      <ReactSVG
        src={checkedIcon}
      />
    </div>
  )
}

export default CheckedIcon
