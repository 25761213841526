import React from 'react'
import Media from 'react-media'

import { CardWrapper, CardContent } from '../../../shared/card'
import MySavedCardsTableContainer from '../../../../../containers/settings/my-account/subscription-and-payment/payment-details/my-saved-cards-table'
import { SCREEN_SIZE_BREAKPOINTS } from '../../../../../constants'

import styles from './payment.css'

const MySavedCardsCard = (props) => {
  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        <Media query={`(min-width: ${SCREEN_SIZE_BREAKPOINTS.MIN_480}px)`}>
          {
            matches => (
              <MySavedCardsTableContainer
                matches={matches}
                {...props}
              />
            )
          }
        </Media>
      </CardContent>
    </CardWrapper>
  )
}

export default MySavedCardsCard
