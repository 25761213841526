import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import {
  head, pipe, isEmpty, complement
} from 'ramda'
import classNames from 'classnames'
import Collapse, { Panel } from 'rc-collapse'
import NoData from './no-data'
import EvIframe from '../../../../../../containers/shared/evergent-iframe'
import {
  CreditCardPanelHeader,
  CreditCardPanelBody
} from './credit-card-panel-components'
import CREDIT_CARD_FRAGMENT from '../../../../../../../graphql/fragments/credit-cards.gql'

import styles from '../payment.css'

const MobileTable = ({
  tableData,
  tableColumns,
  activePanel,
  iframeEditUrl,
  iframeAddUrl,
  iframeIsOpen,
  isAddNewCreditCard,
  activeEditionPanel,
  onIframeLoad,
  handleIframeCancelClick,
  prefetchCreditCardOnActivePanel
}) => {
  const isAddCCIframe = pipe(
    head,
    isEmpty
  )
  const isEditCCIframe = complement(isAddCCIframe)

  if (tableData.length === 0) {
    return <NoData />
  }

  return (
    <>
      {isAddNewCreditCard && (
        <div className={styles.creditCardCollapseItem}>
          <EvIframe
            className={classNames({
              [styles.addCreditCardIframe]: isAddCCIframe,
              [styles.updateCreditCardIframe]: isEditCCIframe
            })}
            src={iframeAddUrl}
            onLoad={onIframeLoad}
            onCancel={handleIframeCancelClick}
          />
        </div>
      )}

      <Collapse accordion onChange={prefetchCreditCardOnActivePanel}>
        {tableData.map((props, index) => (
          <Panel
            // eslint-disable-next-line react/prop-types
            key={props.id}
            showArrow={false}
            className={classNames(
              styles.creditCardCollapseItem,
              // eslint-disable-next-line react/prop-types
              { [styles.itemActive]: activePanel === props.id }
            )}
            header={(
              <CreditCardPanelHeader
                // eslint-disable-next-line react/prop-types
                creditCardName={props.name}
                // eslint-disable-next-line react/prop-types
                isDefault={props.useForSubscriptions || props.useForPurchases}
                // eslint-disable-next-line react/prop-types
                isOpen={activePanel === props.id}
              />
            )}
          >
            <CreditCardPanelBody
              activePanel={activePanel}
              creditCardProps={props}
              columns={tableColumns}
              index={index}
              iframeEditUrl={iframeEditUrl}
              iframeIsOpen={iframeIsOpen}
              isAddNewCreditCard={isAddNewCreditCard}
              activeEditionPanel={activeEditionPanel}
              onIframeLoad={onIframeLoad}
              handleIframeCancelClick={handleIframeCancelClick}
              isAddCCIframe={isAddCCIframe(tableData)}
              isEditCCIframe={isEditCCIframe(tableData)}
            />
          </Panel>
        ))}
      </Collapse>
    </>
  )
}

MobileTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      propType(CREDIT_CARD_FRAGMENT),
      PropTypes.shape({}) // for td iframe placeholder
    ])
  ).isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.func.isRequired,
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string,
      id: PropTypes.string.isRequired,
      minWidth: PropTypes.number
    })
  ).isRequired,
  activePanel: PropTypes.string,
  iframeAddUrl: PropTypes.string,
  iframeEditUrl: PropTypes.string,
  iframeIsOpen: PropTypes.bool.isRequired,
  isAddNewCreditCard: PropTypes.bool,
  activeEditionPanel: PropTypes.string,
  onIframeLoad: PropTypes.func.isRequired,
  handleIframeCancelClick: PropTypes.func.isRequired,
  prefetchCreditCardOnActivePanel: PropTypes.func.isRequired
}

MobileTable.defaultProps = {
  savedCards: [],
  iframeEditUrl: null,
  iframeAddUrl: null,
  activePanel: null,
  activeEditionPanel: null,
  isAddNewCreditCard: false
}

export default MobileTable
