import {
  compose,
  withHandlers,
  withStateHandlers,
  setDisplayName
} from 'recompose'

import { MODALS } from '../../../../constants'
import { withShowModalClick } from '../../../../hoc'
import RecurringVouchersUI from '../../../../components/settings/my-account/vouchers/recurring-voucher'
import AddVoucherUI from '../../../../components/settings/my-account/vouchers/add-voucher'
import styles from '../../../../components/settings/my-account/vouchers/recurring-voucher/recurring-vouchers.css'

const initialCardState = () => ({
  activePanel: null
})

const enhance = compose(
  setDisplayName('RecurringVouchersContainer'),
  withShowModalClick,
  withStateHandlers(initialCardState, {
    setActivePanel: () => voucherCode => {
      return {
        activePanel: voucherCode
      }
    }
  }),
  withHandlers({
    handleTdProps: () => () => {
      return {
        className: styles.vouchersTableData
      }
    },
    handleTheadThProps: () => () => {
      return {
        className: styles.vouchersTableHeaderData
      }
    },
    handleTheadProps: () => () => {
      return {
        className: styles.vouchersTableHeader
      }
    },
    handleTrGroupProps: () => () => {
      return {
        className: styles.vouchersTableGroup
      }
    },
    handleTbodyProps: () => () => {
      return {
        className: styles.vouchersTableBody
      }
    },
    handleIveGotAVoucher: ({ showModal }) => () => {
      showModal(MODALS.qsParams.addVoucher)
    }
  })
)

export const RecurringVouchers = enhance(RecurringVouchersUI)
export const AddVoucher = enhance(AddVoucherUI)
