import React from 'react'
import PropTypes from 'prop-types'
import {
  match, head, pipe, defaultTo
} from 'ramda'

import styles from '../payment.css'

import visa from '../../../../../../../images/cards/Visa.png'
import mastercard from '../../../../../../../images/cards/Mastercard.png'

const cardTypes = {
  Visa: visa,
  MasterCard: mastercard
}

const extractCCDigits = pipe(
  match(/\d+/),
  head,
  defaultTo('')
)

const CardNumberDataCell = ({ type, number }) => {
  return (
    <div className={styles.cardNumberDataCell}>
      <img src={cardTypes[type]} />
      <span className={styles.asterisks}>****</span>
      {extractCCDigits(number)}
    </div>
  )
}

CardNumberDataCell.propTypes = {
  type: PropTypes.string,
  number: PropTypes.string
}

CardNumberDataCell.defaultProps = {
  type: '',
  number: ''
}

export default CardNumberDataCell
