import React from 'react'
import styles from './index.css'
import { CardWrapper, CardContent, CardInnerHeading } from '../../shared/card'

// 3 week offer spark customer will receive on Neon 2 launch date
function BridgingOfferPromotion() {
  const header = `3 week trial`
  const paragraph1 = `As a Spark customer you’re entitled to a 3 week trial of new Neon until 28 July. You just need to login and watch. If you watch more than 3 hours of Neon during the trial period, Spark will automatically add Neon to your Spark bill at the end of the trial, at the special rate of $9.95 a month. You can opt out of this by contacting Spark.`
  const paragraph2 = `And don’t worry, if you haven’t watched more than 3 hours by 28 July, you can still contact Spark and have Neon added to your Spark bill at the special rate of $9.95 a month.`
  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        <CardInnerHeading
          header={header}
          className={styles.bridgingOfferHeading}
        />
        <p className={styles.bridgingOfferParagraph}>{paragraph1}</p>
        <p className={styles.bridgingOfferParagraph}>{paragraph2}</p>
      </CardContent>
    </CardWrapper>
  )
}

export default BridgingOfferPromotion
