import classNames from 'classnames'
import React from 'react'

import styles from '../subscription-plan.css'

const NoPlan = () => {
  const brand = 'Neon'
  const message = `You haven't set up a Plan yet, click the button below to get into ${brand}!`

  return (
    <div className={classNames(styles.container, styles.noBorder)}>
      <h2 className={styles.header}>You have no plan</h2>
      <p className={styles.planDescription}>{message}</p>
    </div>
  )
}

export default NoPlan
