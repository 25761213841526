import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import {
  head, pipe, isEmpty, complement
} from 'ramda'
import classNames from 'classnames'
import NoData from './no-data'
import Table from '../../../../../../containers/settings/shared/table'
import EvIframe from '../../../../../../containers/shared/evergent-iframe'
import CREDIT_CARD_FRAGMENT from '../../../../../../../graphql/fragments/credit-cards.gql'

import styles from '../payment.css'

const DesktopTable = ({
  className,
  tableData,
  tableColumns,
  handleTableProps,
  handleTheadProps,
  handleTheadThProps,
  handleTdProps,
  handleTrGroupProps,
  onIframeLoad,
  iframeEditUrl,
  iframeAddUrl,
  iframeIsOpen,
  cardRowIndex,
  handleIframeCancelClick
}) => {
  return (
    <Table
      className={classNames(className)}
      data={tableData}
      columns={tableColumns}
      showPagination={false}
      NoDataComponent={NoData}
      getTableProps={handleTableProps}
      getTheadProps={handleTheadProps}
      getTheadThProps={handleTheadThProps}
      getTdProps={handleTdProps}
      getTrGroupProps={handleTrGroupProps}
      ExpanderComponent={null}
      expanderDefaults={{ width: 0 }}
      expanded={{ [cardRowIndex]: true }}
      SubComponent={() => {
        if ((iframeEditUrl || iframeAddUrl) && iframeIsOpen) {
          const isAddCCIframe = pipe(
            head,
            isEmpty
          )
          const isEditCCIframe = complement(isAddCCIframe)
          return (
            <span className={styles.rowInfo}>
              <EvIframe
                className={classNames({
                  [styles.addCreditCardIframe]: isAddCCIframe(tableData),
                  [styles.updateCreditCardIframe]: isEditCCIframe(tableData)
                })}
                src={isEditCCIframe(tableData) ? iframeEditUrl : iframeAddUrl}
                onLoad={onIframeLoad}
                onCancel={handleIframeCancelClick}
              />
            </span>
          )
        }
        return null
      }}
    />
  )
}

DesktopTable.propTypes = {
  className: PropTypes.string,
  tableData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      propType(CREDIT_CARD_FRAGMENT),
      PropTypes.shape({}) // for td iframe placeholder
    ])
  ).isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.func.isRequired,
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string,
      id: PropTypes.string.isRequired,
      minWidth: PropTypes.number
    })
  ).isRequired,
  handleTableProps: PropTypes.func.isRequired,
  handleTheadProps: PropTypes.func.isRequired,
  handleTheadThProps: PropTypes.func.isRequired,
  handleTdProps: PropTypes.func.isRequired,
  handleTrGroupProps: PropTypes.func.isRequired,
  onIframeLoad: PropTypes.func.isRequired,
  iframeEditUrl: PropTypes.string.isRequired,
  iframeAddUrl: PropTypes.string,
  iframeIsOpen: PropTypes.bool.isRequired,
  cardRowIndex: PropTypes.number.isRequired,
  handleIframeCancelClick: PropTypes.func.isRequired
}

DesktopTable.defaultProps = {
  iframeAddUrl: null,
  className: ''
}

export default DesktopTable
