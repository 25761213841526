/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext } from 'react'
import { path, pathOr } from 'ramda'
import { addHours } from 'date-fns'

import { SubscriptionStore } from '../subscription-plan'
import { planType } from '../../../../../../modules/shared/subscription-constants'
import { usePlanChangeFlag } from '../../../../../../hooks/usePlanChange'

import styles from '../subscription-plan.css'

const Billing = () => {
  const {
    currentSubscription,
    nextPayment,
    nextMonthSubscription,
    isCancelled,
    isNoSubscription,
    activeVoucher,
    isSparkVoucher,
    recurringVouchers
  } = useContext(SubscriptionStore)
  const getPlanDetails = usePlanChangeFlag()

  if (isCancelled || isNoSubscription) return null

  let nextSubscriptionTitle
  if (nextMonthSubscription.name.toUpperCase() === planType.ANNUAL) {
    nextSubscriptionTitle = getPlanDetails?.annual.staticDetail.name
  } else if (nextMonthSubscription.name.toUpperCase() === planType.BASIC) {
    nextSubscriptionTitle = getPlanDetails?.basic.staticDetail.name
  } else {
    nextSubscriptionTitle = getPlanDetails?.standard.staticDetail.name
  }

  const planPrice = path(['price'])(nextMonthSubscription)
  let voucherPrice = pathOr(0, ['0', 'orderDetails', 'promotion', 'amount'])(
    activeVoucher
  )
  const endDate = path(['0', 'orderDetails', 'promotion', 'endDate'])(
    activeVoucher
  )
  const subscriptionEndsAt = path(['endsAt'])(currentSubscription)
  let isVoucherExhaustedNextMonth = false
  if (subscriptionEndsAt) {
    const subscriptionEndDateTime = getSubscriptionEndTime(subscriptionEndsAt)
    // subscriptionEndDateTime (subscription.currentSubscription.endsAt) only returns date without time, e.g. 21/3/2022
    // however, endDate (recurringVouchers.orderDetails.promotion.endDate) contains time, e.g. 21/3/2022 13:00:00
    // which caused the issue where the app think the voucher is still valid for the next billing cycle
    if (!Number.isNaN(subscriptionEndDateTime)) {
      const d1 = new Date(subscriptionEndDateTime)
      const d2 = new Date(parseInt(endDate, 10))
      if (subscriptionEndDateTime >= parseInt(endDate, 10) ||
        (d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate())
      ) {
        isVoucherExhaustedNextMonth = true
      }
    }
  }

  if (
    (recurringVouchers.length === 1 &&
    isVoucherExhaustedNextMonth)
  ) voucherPrice = 0
  if (parseFloat(voucherPrice) > parseFloat(planPrice)) voucherPrice = planPrice
  const voucherType = isSparkVoucher ? 'Spark' : ''
  const totalPrice = (
    parseFloat(planPrice, 2) - parseFloat(voucherPrice, 2)
  ).toFixed(2)

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Billing</h2>
      <div className={styles.billingContainer}>
        <h4 className={styles.billingheader}>Your next bill</h4>
        <div className={styles.billingWrapper}>
          <div className={styles.billingDetail}>
            <span className={styles.billingName}>
              <span className={styles.billingType}>
                {nextSubscriptionTitle}
              </span>{' '}
              charges
            </span>
            <span className={styles.billingValue}>${planPrice}</span>
          </div>
          {voucherPrice !== 0 && (
            <div className={styles.billingDetail}>
              <span className={styles.billingName}>
                <span className={styles.billingType}>
                  {voucherType} Voucher
                </span>{' '}
                credit
              </span>
              <span className={styles.billingValue}>-${voucherPrice}</span>
            </div>
          )}
        </div>
      </div>

      <div className={styles.billingTotal}>
        <span className={styles.due}>Due {path(['date'])(nextPayment)}</span>
        <div className={styles.price}>
          <span className={styles.total}>${totalPrice}</span>
          <span className={styles.tooltip}>
            Your amount due is calculated by adding any subscription charges
            (including upgrades and downgrades from previous months), and
            subtracting any active vouchers
          </span>
        </div>
      </div>
    </div>
  )
}

const getSubscriptionEndTime = endDateString => {
  const endDateData = endDateString.split('/')
  const endDate = new Date(endDateData[2], parseInt(endDateData[1], 10) - 1, endDateData[0])
  // TODO:  this is workaround to match subscription end date with the one from promotion. Will involve dst check after current MR
  return addHours(endDate, 12).getTime()
}

export default Billing
