import React, { useContext } from 'react'
import CurrentMonthPlan from './current-month-plan'
import NoPlan from './no-plan'
import SuspendedPlan from './subspended-plan'
import CancelledPlan from './cancelled-plan'
import { SubscriptionStore } from '../subscription-plan'

const SubscriptionInfo = () => {
  const { isNoSubscription, isCancelled, isSuspended } = useContext(
    SubscriptionStore
  )

  if (isNoSubscription) return <NoPlan />
  if (isCancelled) return <CancelledPlan />
  if (isSuspended) return <SuspendedPlan />

  return <CurrentMonthPlan />
}

export default SubscriptionInfo
