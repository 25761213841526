import React from 'react'

import neonLoadingImg from '../../../../../../../images/neon/loading.svg'

import styles from '../payment.css'

const LoadingTable = () => {
  return (
    <div className={styles.loading}>
      <img src={neonLoadingImg} />
    </div>
  )
}

export default LoadingTable
