import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import { roundIcon } from '../../../../shared/round-icon.css'

import styles from '../payment.css'

import addIcon from '../../../../../../../images/add-to-list.svg'

const AddIcon = React.memo(({
  onClick, onMouseOver, isDisabled, id
}) => {
  return (
    <div
      className={styles.addButton}
      onClick={onClick}
      onMouseOver={onMouseOver}
      id={id}
    >
      Add
      <ReactSVG
        src={addIcon}
        beforeInjection={svg => {
          svg.setAttribute(
            'class',
            classNames(roundIcon, { [styles.disabled]: isDisabled })
          )
        }}
      />
    </div>
  )
})

AddIcon.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
}

export default AddIcon
