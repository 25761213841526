import React from 'react'

import styles from '../subscription-plan.css'
import { E2E_SELECTORS } from '../../../../../../e2e-selectors'
import { priceChangeAlertMessage } from '../../../../../../modules/shared/subscription-constants'

const PriceChangeAlert = () => {
  return (
    <div className={styles.priceChangeAlertContainer}>
      <span
        data-e2e={E2E_SELECTORS.PRICE_CHANGE_ALERT}
      >
        {priceChangeAlertMessage.myAccount}
      </span>
    </div>
  )
}

export default PriceChangeAlert
