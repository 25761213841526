import React from 'react'
import classNames from 'classnames'

import CardNicknameDataCell from './card-nickname-data-cell'
import CardNumberDataCell from './card-number-data-cell'
import CheckedIcon from './checked-icon'
import UncheckedIcon from './unchecked-icon'
import RemoveIcon from './remove-icon'
import EditIcon from './edit-icon'

import styles from '../payment.css'

const getTableColumns = (
  // if matches = false, generate the columns for mobile screens,
  // if true, generate the columns for desktop screens
  matches, {
    handleRemoveCreditCard,
    handleEditCreditCard,
    handleCheckboxDefaultCard
  }
) => {
  const columns = [
    {
      Header: matches ? 'Card Number' : '',
      id: 'number',
      accessor: 'number',
      Cell: d => (
        <span className={styles.cellInfo}>
          <CardNumberDataCell
            type={d.original.type}
            number={d.original.number}
          />
        </span>
      ),
      minWidth: 35
    },
    {
      Header: 'Subscription Payments',
      id: 'useForSubscriptions',
      accessor: 'useForSubscriptions',
      Cell: d => (
        d.value ? <CheckedIcon /> : (
          <UncheckedIcon
            onClick={() => handleCheckboxDefaultCard(d, d.columnName)}
          />
        )
      ),
      minWidth: 27
    },
    {
      Header: 'Rentals',
      id: 'useForPurchases',
      accessor: 'useForPurchases',
      Cell: d => (
        d.value ? <CheckedIcon /> : (
          <UncheckedIcon
            onClick={() => handleCheckboxDefaultCard(d, d.columnName)}
          />
        )
      ),
      minWidth: 27
    },
    {
      Header: 'Remove',
      id: 'remove',
      Cell: d => (
        <span className={styles.cellInfo}>
          <RemoveIcon
            onClick={() => { handleRemoveCreditCard(d.original.id) }}
            isDefault={d.original.useForSubscriptions || d.original.useForPurchases}
          />
        </span>
      ),
      minWidth: 20
    },
    {
      Header: 'Edit',
      id: 'edit',
      Cell: d => (
        <span className={styles.cellInfo}>
          <EditIcon
            onClick={() => {
              handleEditCreditCard(d.original.id, d.index)
            }}
          />
        </span>
      ),
      minWidth: 20
    }
  ]

  // The Card Name is going to be displayed only on desktop screens in the first column of the table
  if (matches) {
    columns.unshift({
      Header: 'Card Name',
      id: 'nickname',
      accessor: 'nickname',
      Cell: d => (
        <span className={classNames(styles.cellInfo, styles.nicknameCell)}>
          <CardNicknameDataCell
            nickname={d.value}
          // TODO: LBXW-1418: Complete middleware api integration for invalid credit card status
          />
        </span>
      ),
      minWidth: 35
    })
  }
  return columns
}

export default getTableColumns
