import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../../../../containers/settings/shared/table'

export const DesktopTable = ({
  className,
  tableColumns,
  vouchers,
  handleTdProps,
  handleTheadThProps,
  handleTheadProps,
  handleTrGroupProps,
  handleTbodyProps
}) => {
  return (
    <Table
      className={className}
      columns={tableColumns}
      data={vouchers}
      showPagination={false}
      getTdProps={handleTdProps}
      getTheadThProps={handleTheadThProps}
      getTheadProps={handleTheadProps}
      getTrGroupProps={handleTrGroupProps}
      getTbodyProps={handleTbodyProps}
    />
  )
}

DesktopTable.propTypes = {
  className: PropTypes.string.isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.func.isRequired,
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string,
      id: PropTypes.string.isRequired,
      minWidth: PropTypes.number
    })
  ).isRequired,
  vouchers: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired
    })
  ).isRequired,
  handleTdProps: PropTypes.func.isRequired,
  handleTheadThProps: PropTypes.func.isRequired,
  handleTheadProps: PropTypes.func.isRequired,
  handleTrGroupProps: PropTypes.func.isRequired,
  handleTbodyProps: PropTypes.func.isRequired
}
