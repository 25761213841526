import { productsForAvod } from '../constants'

const isDowngrading = (subscription, upcomingSubscription) => {
  let productList = null

  const { annualForAvod: annual, standardForAvod: premium, basicForAvod: basic } = productsForAvod
  productList = { annual, premium, basic }

  return (
    !!subscription &&
    !!upcomingSubscription &&
    (
      (subscription.sku === productList.annual.sku && upcomingSubscription.sku === productList.premium.sku) ||
      (subscription.sku === (productList.premium.sku || productList?.standard?.sku) && upcomingSubscription.sku === productList?.basic?.sku) ||
      (subscription.sku === productList.annual.sku && upcomingSubscription.sku === productList?.basic?.sku)
    )
  )
}

export default isDowngrading
