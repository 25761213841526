import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { decodeHTMLEntity } from '../../../../../../lib/utils'

import InvalidExclamation from './invalid-exclamation-icon'

import styles from '../payment.css'

const CardNicknameDataCell = ({ nickname, isInvalid }) => {
  return (
    <div className={classNames(styles.cardNicknameDataCell)}>
      <div className={classNames({ [styles.invalid]: isInvalid })}>
        <span className={styles.nickname}>{decodeHTMLEntity(nickname)}</span>
        { isInvalid && <span className={styles.message}>Payment Failed</span> }
      </div>
      { isInvalid && <InvalidExclamation /> }
    </div>
  )
}

CardNicknameDataCell.propTypes = {
  nickname: PropTypes.string,
  isInvalid: PropTypes.bool
}

CardNicknameDataCell.defaultProps = {
  nickname: '',
  isInvalid: false
}

export default CardNicknameDataCell
