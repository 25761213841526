import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import { CardInnerHeading } from '../../../shared/card'

import addIcon from '../../../../../../images/add-to-list.svg'
import { roundIcon } from '../../../shared/round-icon.css'

import styles from '../recurring-voucher/recurring-vouchers.css'

const AddVoucher = ({ handleIveGotAVoucher }) => {
  return (
    <div className={styles.voucherContainer}>
      <CardInnerHeading
        header="Got a voucher?"
        className={styles.innerHeader}
      />
      <button
        className={styles.iveGotAVoucher}
        onClick={handleIveGotAVoucher}
        type="submit"
      >
        <div className={styles.addButton}>
          Add Voucher
          <ReactSVG
            src={addIcon}
            className={styles.svgWrapper}
            beforeInjection={svg => {
              svg.setAttribute('class', roundIcon)
            }}
          />
        </div>
      </button>
    </div>
  )
}

AddVoucher.propTypes = {
  handleIveGotAVoucher: PropTypes.func.isRequired
}

export default AddVoucher
