import React from 'react'
import PropTypes from 'prop-types'
import { path, filter } from 'ramda'
import { propType } from 'graphql-anywhere'
import SUBSCRIPTION_INFORMATION_FRAGMENT from '../../../../../../graphql/fragments/subscription-information.gql'
import { CardWrapper, CardContent } from '../../../shared/card'
import SubscriptionInfo from './subscription-info'
import Billing from './billing'
import ManageSubscription from './manage-subscription/index'
import { getVoucherType } from '../../vouchers/recurring-voucher/recurring-vouchers'
import { SUBSCRIPTION_STATUS } from '../../../../../constants'
import styles from './subscription-plan.css'
import isDowngradingSubscription from '../../../../../modules/change-subscription/lib/is-downgrading'
import isUpgradingSubscription from '../../../../../modules/change-subscription/lib/is-upgrading'

export const SubscriptionStore = React.createContext()

const SubscriptionPlan = ({
  isCancelled,
  subscription,
  renewalStatus,
  handleUpdateSubscription
}) => {
  let { currentSubscription, recurringVouchers } = subscription
  const status = path(['status'])(subscription)
  let isNoSubscription = status === SUBSCRIPTION_STATUS.NONE
  currentSubscription = removeExpiredPromotion(currentSubscription)
  recurringVouchers = removeExpiredVouchers(recurringVouchers)
  const upcomingSubscription = path(['upcomingSubscription'])(subscription)
  if (isCancelledByUser(isCancelled, currentSubscription)) {
    isNoSubscription = true
    isCancelled = false
  }
  const activeVoucher = filter(
    voucher => path(['orderDetails', 'status'])(voucher) === 'Active' && !/SFT/.test(path(['orderDetails', 'promotion', 'id'])(voucher)) && !/BFT/.test(path(['orderDetails', 'promotion', 'id'])(voucher))
  )(recurringVouchers)
  const isSparkVoucher =
    getVoucherType(
      path(['0', 'orderDetails', 'promotion', 'id'])(activeVoucher)
    ) === 'Spark Standard Voucher'
  const nextMonthSubscription = upcomingSubscription || currentSubscription

  const upcomingFinalBillSubscription = path(['upcomingFinalBillSubscription'])(
    subscription
  )

  const manageSubsOptions = path(['manageSubsOptions'])(subscription)

  let value = {
    currentSubscription,
    renewalStatus,
    isNoSubscription,
    isCancelled,
    isSuspended: status === SUBSCRIPTION_STATUS.SUSPENDED,
    isDowngrading: renewalStatus === SUBSCRIPTION_STATUS.DOWNGRADING,
    nextPayment: path(['nextPayment'])(subscription),
    recentPayments: path(['recentPayments', '0'])(subscription),
    recurringVouchers,
    nextMonthSubscription,
    upcomingFinalBillSubscription,
    handleUpdateSubscription,
    upcomingSubscription,
    status,
    activeVoucher,
    isSparkVoucher,
    manageSubsOptions
  }

  const evSubscriptionStatus =
    currentSubscription && currentSubscription.evSubscriptionStatus
  const evUpcomingSubscriptionStatus =
    (upcomingSubscription && upcomingSubscription.evSubscriptionStatus) ||
    (upcomingFinalBillSubscription &&
      upcomingFinalBillSubscription.evSubscriptionStatus)

  const isDowngrading = isDowngradingSubscription(
    currentSubscription,
    upcomingSubscription || upcomingFinalBillSubscription
  )
  const isUpgrading = isUpgradingSubscription(
    currentSubscription,
    upcomingSubscription || upcomingFinalBillSubscription
  )

  value = {
    ...value,
    evSubscriptionStatus,
    evUpcomingSubscriptionStatus,
    isDowngrading,
    isUpgrading
  }

  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        <SubscriptionStore.Provider value={value}>
          <SubscriptionInfo />
          <Billing />
          <ManageSubscription />
        </SubscriptionStore.Provider>
      </CardContent>
    </CardWrapper>
  )
}

SubscriptionPlan.propTypes = {
  subscription: propType(SUBSCRIPTION_INFORMATION_FRAGMENT).isRequired,
  handleUpdateSubscription: PropTypes.func.isRequired,
  isCancelled: PropTypes.bool.isRequired,
  renewalStatus: PropTypes.string.isRequired
}

export default SubscriptionPlan

// TODO: evergent currently handle it incorrectly
// below is a workaround for subscription cancelled by evergent
function isCancelledByUser(isCancelled, currentSubscription) {
  const endDate = path(['endsAt'])(currentSubscription)
  if (!endDate) return false
  const [day, month, year] = endDate.split('/')
  const cancelledDate = new Date(year, month - 1, day).getTime()
  return (
    isCancelled && cancelledDate < new Date().getTime() // the cancel date is before now
  )
}

export function removeExpiredVouchers(recurringVouchers) {
  if (!Array.isArray(recurringVouchers)) return recurringVouchers
  return recurringVouchers.filter(recurringVoucher => isPromotionValid(path(['orderDetails', 'promotion', 'endDate'])(recurringVoucher)))
}

export function removeExpiredPromotion(currentSubscription) {
  if (!currentSubscription) return null
  const currentSubscriptionExpiration = path(['promotion', 'expiration'])(
    currentSubscription
  )
  if (isPromotionValid(currentSubscriptionExpiration)) {
    return currentSubscription
  }
  return { ...currentSubscription, promotion: {} }
}

function isPromotionValid(promotionEnddate) {
  return parseInt(promotionEnddate, 10) > new Date().getTime()
}
