import React, { useContext } from 'react'
import classNames from 'classnames'
import { format } from 'date-fns'

import { SubscriptionStore } from '../subscription-plan'
import styles from '../subscription-plan.css'
import { E2E_SELECTORS } from '../../../../../../e2e-selectors'

const CancelledPlan = () => {
  const { currentSubscription } = useContext(SubscriptionStore)
  let endDateFormatted
  if (currentSubscription.endsAt) {
    const [day, month, year] = currentSubscription.endsAt.split('/')
    endDateFormatted = format(
      new Date(year, month - 1, day),
      'd MMMM yyyy'
    )
  }
  return (
    <div className={classNames(styles.container, styles.noBorder)}>
      <h2
        className={styles.header}
        data-e2e={E2E_SELECTORS.YOU_HAVE_NO_PLAN}
      >
        You have no plan
      </h2>
      <p
        className={styles.planDescription}
        data-e2e={E2E_SELECTORS.PLAN_DESCRIPTION}
      >
        {`Your ${currentSubscription.name} Plan has been cancelled but will remain active until ${endDateFormatted}`}
      </p>
    </div>
  )
}

export default CancelledPlan
