import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { propOr } from 'ramda'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import { setManageSubsOptions, setCurrentSubscriptionName } from '../../../../../../actions/index'
import { SubscriptionStore } from '../subscription-plan'
import { withHandleResume } from '../../../../../../hoc'
import { useOneWaySubscriptionChange } from '../../../../../../hooks/useOneWaySubscriptionChange'
import closeIcon from '../../../../../../../images/closeModal.svg'
import editIcon from '../../../../../../../images/pencil.svg'
import resumeIcon from '../../../../../../../images/resume.svg'
import addIcon from '../../../../../../../images/add-to-list.svg'

import { MODALS, EV_SUBSCRIPTION_STATUS, MANAGE_PLAN_OPTIONS } from '../../../../../../constants'
import styles from '../subscription-plan.css'
import { modalContent } from '../../../../../../modules/shared/subscription-constants'
import {
  isUpgradeAllowed,
  isDowngradeAllowed
} from '../../../../../../modules/change-subscription'
import renderButton from './render-button'

const ManageSubscriptionWithAnnual = ({
  showModal, mutateAndLoading
}) => {
  const {
    currentSubscription,
    upcomingFinalBillSubscription,
    handleUpdateSubscription,
    evSubscriptionStatus,
    evUpcomingSubscriptionStatus,
    manageSubsOptions,
    recurringVouchers
  } = useContext(SubscriptionStore)
  const [cancelSubscription, setCancelSubscription] = useState()
  const [addSubscription, setAddSubscription] = useState()
  const [updateSubscription, setUpdateSubscription] = useState()
  const [resumeSubscription, setResumeSubscription] = useState()
  const [upgradeSubscription, setUpgradeSubscription] = useState()
  const [downgradeSubscription, setDowngradeSubscription] = useState()

  const oneWayChangeDirection = useOneWaySubscriptionChange(manageSubsOptions)
  const sku = propOr('', 'sku', currentSubscription)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setManageSubsOptions(manageSubsOptions))
    currentSubscription && dispatch(setCurrentSubscriptionName(currentSubscription.name))
  }, [manageSubsOptions, sku])

  // Cancel
  useEffect(() => {
    if (manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.CANCEL)) {
      const cancel = renderButton(
        () => {
          manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.UPGRADE) || manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.DOWNGRADE)
            ? showModal(MODALS.qsParams.subscriptionOffer)
            : showModal(MODALS.qsParams.subscriptionCancel)
        },
        'cancel',
        classnames(styles.button, styles.cancelButton),
        'Cancel',
        closeIcon
      )
      setCancelSubscription(cancel)
    } else {
      setCancelSubscription(null)
    }
  }, [manageSubsOptions])

  // Add Plan
  useEffect(() => {
    if (manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.ADD)) {
      const choose = renderButton(
        () => {
          showModal(MODALS.qsParams.addSubscription, {
            content: modalContent.selection
          })
        },
        'choose',
        classnames(styles.button, styles.addButton),
        'Add Plan',
        addIcon
      )
      setAddSubscription(choose)
    } else {
      setAddSubscription(null)
    }
  }, [manageSubsOptions])

  // Update Subscription
  useEffect(() => {
    if (manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.UPGRADE) || manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.DOWNGRADE)) {
      const choose = renderButton(
        () => {
          showModal(MODALS.qsParams.addSubscription, {
            content: modalContent.selection,
            currentSubscription: currentSubscription.name,
            oneWayChangeDirection,
            hasRecurringVouchers: !!(recurringVouchers && recurringVouchers.length)
          })
        },
        'update',
        styles.button,
        'Change Plan',
        editIcon
      )
      setUpdateSubscription(choose)
    } else {
      setUpdateSubscription(null)
    }
  }, [manageSubsOptions, sku])

  // Resume subscription
  useEffect(() => {
    if (manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.RESUME)) {
      const upcomingFinalBillSku = propOr(
        '',
        'sku',
        upcomingFinalBillSubscription
      )
      const isUpcomingCancelled =
        evUpcomingSubscriptionStatus === EV_SUBSCRIPTION_STATUS.FINAL_BILL
      const resume = renderButton(
        withHandleResume({
          mutateAndLoading,
          sku,
          isUpcomingCancelled,
          upcomingFinalBillSku,
          handleUpdateSubscription
        }),
        'Resume',
        styles.button,
        'Resume Plan',
        resumeIcon
      )
      setResumeSubscription(resume)
    } else {
      setResumeSubscription(null)
    }
  }, [
    manageSubsOptions,
    upcomingFinalBillSubscription,
    mutateAndLoading,
    sku,
    handleUpdateSubscription,
    evSubscriptionStatus,
    evUpcomingSubscriptionStatus
  ])

  // Upgrade
  useEffect(() => {
    const upgradeAllowed = isUpgradeAllowed(
      evSubscriptionStatus,
      evUpcomingSubscriptionStatus,
      sku
    )
    if (upgradeAllowed) {
      const upgrade = renderButton(
        () => showModal(MODALS.qsParams.upgradeSubscription),
        'Upgrade',
        classnames(styles.button, styles.changeSubscriptionButton),
        'Switch to Annual Subscription'
      )

      setUpgradeSubscription(upgrade)
    } else {
      setUpgradeSubscription(null)
    }
  }, [evSubscriptionStatus, evUpcomingSubscriptionStatus, sku])

  // Downgrade
  useEffect(() => {
    const downgradeAllowed = isDowngradeAllowed(
      evSubscriptionStatus,
      evUpcomingSubscriptionStatus,
      sku
    )
    if (downgradeAllowed) {
      const downgrade = renderButton(
        () => showModal(MODALS.qsParams.downgradeSubscription),
        'Downgrade',
        classnames(styles.button, styles.changeSubscriptionButton),
        'Switch to Monthly Subscription'
      )
      setDowngradeSubscription(downgrade)
    } else {
      setDowngradeSubscription(null)
    }
  }, [evSubscriptionStatus, evUpcomingSubscriptionStatus, sku])

  if (
    !addSubscription &&
    !resumeSubscription &&
    !upgradeSubscription &&
    !downgradeSubscription &&
    !cancelSubscription &&
    !updateSubscription
  ) {
    return null
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Manage your plan</h2>
      <div>
        {addSubscription}
        {resumeSubscription}
        {updateSubscription}
        {cancelSubscription}
      </div>
    </div>
  )
}

ManageSubscriptionWithAnnual.propTypes = {
  showModal: PropTypes.func.isRequired,
  mutateAndLoading: PropTypes.func.isRequired
}

export default ManageSubscriptionWithAnnual
