import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classNames from 'classnames'

import EvIframe from '../../../../../../containers/shared/evergent-iframe'
import RoundIcon from '../../../../shared/round-icon'
import arrowUpDark from '../../../../../../../images/arrow-up-dark.svg'
import arrowDownDark from '../../../../../../../images/arrow-down-dark.svg'
import CREDIT_CARD_FRAGMENT from '../../../../../../../graphql/fragments/credit-cards.gql'
import styles from '../payment.css'

export const CreditCardPanelHeader = ({ creditCardName, isDefault, isOpen }) => {
  const headerText = isDefault ? `${creditCardName} (Default)` : creditCardName
  return (
    <div className={styles.accordionHeader}>
      <div className={styles.creditCardName}>
        { headerText }
      </div>
      { isOpen ? <ArrowUpIcon /> : <ArrowDownIcon /> }
    </div>
  )
}

CreditCardPanelHeader.propTypes = {
  creditCardName: PropTypes.string,
  isDefault: PropTypes.bool,
  isOpen: PropTypes.bool
}

CreditCardPanelHeader.defaultProps = {
  creditCardName: '',
  isDefault: false,
  isOpen: false
}

export const CreditCardPanelBody = ({
  columns,
  creditCardProps,
  index,
  activePanel,
  iframeEditUrl,
  iframeIsOpen,
  isAddNewCreditCard,
  activeEditionPanel,
  onIframeLoad,
  handleIframeCancelClick,
  isAddCCIframe,
  isEditCCIframe
}) => {
  return (
    <>
      {
        !iframeIsOpen && (activePanel === creditCardProps.id) && (
          <div className={styles.accordionBody}>
            {columns.map((col) => {
              return (
                <CreditCardPanelRow
                  key={col.id}
                  columnName={col.Header}
                  columnCell={col.Cell({
                    value: creditCardProps[col.id],
                    original: creditCardProps,
                    columnName: col.id, // column identifier
                    index // row number
                  })}
                />
              )
            })}
          </div>
        )
      }
      {
        iframeEditUrl && iframeIsOpen &&
        (activeEditionPanel === creditCardProps.id || isAddNewCreditCard) && (
          <EvIframe
            className={classNames({
              [styles.addCreditCardIframe]: isAddCCIframe,
              [styles.updateCreditCardIframe]: isEditCCIframe
            })}
            src={iframeEditUrl}
            onLoad={onIframeLoad}
            onCancel={handleIframeCancelClick}
          />
        )
      }
    </>
  )
}

CreditCardPanelBody.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    Cell: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string,
    id: PropTypes.string,
    minWidth: PropTypes.number
  })).isRequired,
  creditCardProps: propType(CREDIT_CARD_FRAGMENT).isRequired,
  index: PropTypes.number.isRequired,
  activePanel: PropTypes.string,
  iframeEditUrl: PropTypes.string,
  iframeIsOpen: PropTypes.bool.isRequired,
  isAddNewCreditCard: PropTypes.bool,
  activeEditionPanel: PropTypes.string,
  onIframeLoad: PropTypes.func.isRequired,
  handleIframeCancelClick: PropTypes.func.isRequired,
  isAddCCIframe: PropTypes.bool.isRequired,
  isEditCCIframe: PropTypes.bool.isRequired
}

CreditCardPanelBody.defaultProps = {
  iframeEditUrl: null,
  activePanel: null,
  activeEditionPanel: null,
  isAddNewCreditCard: false
}

export const ArrowUpIcon = () => {
  return (
    <span className={styles.upArrowButton}>
      <RoundIcon alt="Arrow Up" src={arrowUpDark} />
    </span>
  )
}

export const ArrowDownIcon = () => {
  return (
    <span className={styles.downArrowButton}>
      <RoundIcon alt="Arrow Down" src={arrowDownDark} />
    </span>
  )
}

export const CreditCardPanelRow = ({ columnName, columnCell }) => {
  return (
    <div className={styles.accordionGroup}>
      <div className={styles.columnName}>{columnName}</div>
      {columnCell}
    </div>
  )
}

CreditCardPanelRow.propTypes = {
  columnName: PropTypes.string.isRequired,
  columnCell: PropTypes.element.isRequired
}
