import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import {
  DesktopTable,
  MobileTable,
  AddIcon as AddCreditCardIcon
} from './payment-table-components'
import styles from './payment.css'

import { CardInnerHeading } from '../../../shared/card'
import CREDIT_CARD_FRAGMENT from '../../../../../../graphql/fragments/credit-cards.gql'

import ConfirmationModal from '../../../../shared/confirmation-modal'

const MySavedCardsTable = ({
  activePanel,
  setActivePanel,
  matches,
  isConfirmationModalOpen,
  handleDeleteCardModalCancelClick,
  handleDeleteCardModalConfirmClick,
  handleAddCreditCard,
  prefetchCreditCardAddResponse,
  hasReachedLimit,
  ...rest
}) => {
  return (
    <>
      <CardInnerHeading
        className={styles.cardInnerHeading}
        header="My saved cards"
        subHeader="Your default credit card will be used for monthly
          payments and movie rentals you authorise with your password or PIN."
      />
      <AddCreditCardIcon
        onMouseOver={prefetchCreditCardAddResponse}
        onClick={handleAddCreditCard}
        isDisabled={hasReachedLimit}
        id="AddCreditCardIcon"
      />
      {matches ? (
        // Desktop View
        <DesktopTable className={styles.mySavedCardsTable} {...rest} />
      ) : (
        // show mobile table when screen <= 480px
        <MobileTable
          className={styles.mySavedCardsTable}
          setActivePanel={setActivePanel}
          activePanel={activePanel}
          {...rest}
        />
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          header="Remove this credit card?"
          message="Are you sure you want to remove this credit card from your account?"
          confirmText="Yes, Remove this card"
          cancelText="I'll keep this card"
          onCancelClick={handleDeleteCardModalCancelClick}
          onConfirmClick={handleDeleteCardModalConfirmClick}
          customWrapperClass={styles.removeCCardConfirmationWrapper}
        />
      )}
    </>
  )
}

MySavedCardsTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      propType(CREDIT_CARD_FRAGMENT),
      PropTypes.shape({}) // for td iframe placeholder
    ])
  ).isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.func.isRequired,
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string,
      id: PropTypes.string.isRequired,
      minWidth: PropTypes.number
    })
  ).isRequired,
  activePanel: PropTypes.string,
  setActivePanel: PropTypes.func.isRequired,
  matches: PropTypes.bool.isRequired,
  handleTableProps: PropTypes.func.isRequired,
  handleTheadProps: PropTypes.func.isRequired,
  handleTheadThProps: PropTypes.func.isRequired,
  handleTdProps: PropTypes.func.isRequired,
  handleTrGroupProps: PropTypes.func.isRequired,
  isConfirmationModalOpen: PropTypes.bool.isRequired,
  handleDeleteCardModalCancelClick: PropTypes.func.isRequired,
  handleDeleteCardModalConfirmClick: PropTypes.func.isRequired,
  onIframeLoad: PropTypes.func.isRequired,
  iframeIsOpen: PropTypes.bool.isRequired,
  cardRowIndex: PropTypes.number.isRequired,
  handleAddCreditCard: PropTypes.func.isRequired,
  prefetchCreditCardAddResponse: PropTypes.func.isRequired,
  hasReachedLimit: PropTypes.bool.isRequired,
  handleIframeCancelClick: PropTypes.func.isRequired
}

MySavedCardsTable.defaultProps = {
  activePanel: null
}

export default MySavedCardsTable
