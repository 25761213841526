import { productsForAvod } from '../constants'

const isUpgrading = (subscription, upcomingSubscription) => {
  let productList = null

  const { annualForAvod: annual, standardForAvod: premium, basicForAvod: basic } = productsForAvod
  productList = { annual, premium, basic }

  return (
    !!subscription &&
    !!upcomingSubscription &&
    (
      (
        subscription.sku === (productList.premium.sku || productList?.standard?.sku) &&
        upcomingSubscription.sku === productList.annual.sku
      ) ||
      (
        subscription.sku === productList?.basic?.sku &&
        upcomingSubscription.sku === productList.premium.sku
      ) ||
      (
        subscription.sku === productList?.basic?.sku &&
        upcomingSubscription.sku === productList.annual.sku
      )
    )
  )
}

export default isUpgrading
