import React from 'react'
import ReactSVG from 'react-svg'

import AsyncReactTippy from '../../../../../../containers/async-react-tippy'

import styles from '../payment.css'

import infoIcon from '../../../../../../../images/info.svg'

const InvalidExclamation = () => {
  return (
    <span className={styles.iconWrapper}>
      <AsyncReactTippy
        title="Please check your card details. You can setup a new card by clicking ‘Add’ above."
        position="top"
        trigger="mouseenter"
        arrow
        duration="0"
      >
        <ReactSVG
          src={infoIcon}
          beforeInjection={(svg) => {
            svg.setAttribute('class', styles.exclamationIcon)
          }}
          className={styles.svgWrapper}
        />
      </AsyncReactTippy>
    </span>
  )
}

export default InvalidExclamation
