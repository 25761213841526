import React from 'react'
import { path, pathOr } from 'ramda'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import Section from '../../shared/section'
import MySavedCardsCardContainer
  from '../../../../containers/settings/my-account/subscription-and-payment/payment-details/my-saved-cards-card'
import SubscriptionPlan from './subscription-plan'
import Vouchers from '../vouchers'
import {
  SUBSCRIPTION_STATUS,
  EV_SUBSCRIPTION_STATUS
} from '../../../../constants'
import SUBSCRIPTION_INFORMATION_FRAGMENT from '../../../../../graphql/fragments/subscription-information.gql'
import { TESTERS } from '../../../../lib/tester-classnames'
import DcbSubscription from '../dcb-subscription'
import BridingOfferPromotion from '../bridging-offer-promotion'

function SubscriptionAndPayment({
  activeSubscription,
  handleUpdateSubscription
}) {
  let isCancelled =
    path(['status'], activeSubscription) === SUBSCRIPTION_STATUS.CANCELLED ||
    path(['renewalStatus'], activeSubscription) ===
    SUBSCRIPTION_STATUS.CANCELLED

  const recurringVouchers = pathOr(
    [],
    ['recurringVouchers']
  )(activeSubscription)
  const isSuspended =
    path(['status'], activeSubscription) === SUBSCRIPTION_STATUS.SUSPENDED
  const renewalStatus = pathOr('', ['renewalStatus'])(activeSubscription)

  const dcbSubscriptionInfo = path(['dcbSubscriptionInfo'], activeSubscription)

  const isBridgingOfferPromotion = pathOr(
    false,
    ['currentSubscription', 'promotion', 'isBridgingOfferPromotion'],
    activeSubscription
  )

  const subscriptionPlanVisible =
    !dcbSubscriptionInfo && !isBridgingOfferPromotion
  const vouchersVisible =
    !isSuspended && !dcbSubscriptionInfo && !isBridgingOfferPromotion
  const dcbSubscriptionVisible =
    !!dcbSubscriptionInfo && !isBridgingOfferPromotion
  const bridginOfferPromotionVisible = isBridgingOfferPromotion

  let voucherNotAllowed = false
  let voucherMessage = ''
  // Override value of isCancelled and voucherVisible if Annual Plan
  const {
    currentSubscription,
    upcomingSubscription,
    upcomingFinalBillSubscription
  } = activeSubscription
  const nextSubscription =
    upcomingSubscription || upcomingFinalBillSubscription
  const evSubscriptionStatus = path(
    ['evSubscriptionStatus'],
    currentSubscription
  )
  const evUpcomingSubscriptionStatus = path(
    ['evSubscriptionStatus'],
    nextSubscription
  )

  isCancelled =
    evSubscriptionStatus === EV_SUBSCRIPTION_STATUS.FINAL_BILL &&
    (!evUpcomingSubscriptionStatus ||
      evUpcomingSubscriptionStatus === EV_SUBSCRIPTION_STATUS.FINAL_BILL)

  if (vouchersVisible) {
    // Not allowed when cancel after downgrading subscription.
    // Not allowed if the FF hide_voucher_override_price is on LBX-7502.
    voucherNotAllowed = (evSubscriptionStatus === EV_SUBSCRIPTION_STATUS.FINAL_BILL &&
        evUpcomingSubscriptionStatus === EV_SUBSCRIPTION_STATUS.FINAL_BILL)

    voucherMessage = voucherNotAllowed
      ? `Sorry, you can't use a voucher at this time`
      : ''
  }
  return (
    <>
      {subscriptionPlanVisible && (
        <Section title="My Plan" testerClassName={TESTERS.ACCOUNT.TV_PLAN}>
          <SubscriptionPlan
            subscription={activeSubscription}
            handleUpdateSubscription={handleUpdateSubscription}
            recurringVouchers={recurringVouchers}
            renewalStatus={renewalStatus}
            isCancelled={isCancelled}
          />
        </Section>
      )}

      {dcbSubscriptionVisible && (
        <Section title="My Plan">
          <DcbSubscription dcbSubscriptionInfo={dcbSubscriptionInfo} />
        </Section>
      )}

      {bridginOfferPromotionVisible && (
        <Section title="My Plan">
          <BridingOfferPromotion />
        </Section>
      )}

      {vouchersVisible && (
        <Section title="Vouchers">
          <Vouchers
            recurringVouchers={recurringVouchers}
            renewalStatus={renewalStatus}
            addVoucherVisible={!voucherNotAllowed}
            message={voucherMessage}
          />
        </Section>
      )}

      <Section
        title="Payment Details"
        testerClassName={TESTERS.ACCOUNT.PAYMENT_DETAILS}
      >
        <MySavedCardsCardContainer activeSubscription={activeSubscription} />
      </Section>
    </>
  )
}

SubscriptionAndPayment.propTypes = {
  activeSubscription: propType(SUBSCRIPTION_INFORMATION_FRAGMENT).isRequired,
  handleUpdateSubscription: PropTypes.func.isRequired
}

export default SubscriptionAndPayment
