import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'

import REACTIVATE_SUBSCRIPTION_MUTATION from '../../../../../../../graphql/mutations/reactivate-subscription.gql'
import { withShowModalClick, withMutateAndLoading } from '../../../../../../hoc'

import ManageSubscriptionWithAnnual from './manage-subscription-with-annual'

const enhance = compose(
  graphql(REACTIVATE_SUBSCRIPTION_MUTATION),
  withMutateAndLoading,
  withShowModalClick
)

const ManageSubscription = ({
  showModal, mutateAndLoading
}) => {
  return (
    <ManageSubscriptionWithAnnual
      showModal={showModal}
      mutateAndLoading={mutateAndLoading}
    />
  )
}

ManageSubscription.propTypes = {
  showModal: PropTypes.func.isRequired,
  mutateAndLoading: PropTypes.func.isRequired
}

export default enhance(ManageSubscription)
