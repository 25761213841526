import React from 'react'
import PropTypes from 'prop-types'
import Collapse, { Panel } from 'rc-collapse'
import {
  VoucherPanelBody,
  VoucherPanelHeader
} from './recurring-voucher-panel-components'

import styles from './recurring-vouchers.css'

export const MobileTable = ({
  vouchers,
  tableColumns,
  setActivePanel,
  activePanel
}) => {
  return (
    <Collapse accordion onChange={setActivePanel}>
      {vouchers.map(voucherDetails => (
        <Panel
          key={voucherDetails.code}
          showArrow={false}
          className={styles.voucherCollapseItem}
          header={(
            <VoucherPanelHeader
              vouchers={vouchers}
              props={voucherDetails}
              isOpen={activePanel === voucherDetails.code}
            />
          )}
        >
          <VoucherPanelBody
            vouchersProps={voucherDetails}
            columns={tableColumns}
            isOpen={activePanel === voucherDetails.code}
          />
        </Panel>
      ))}
    </Collapse>
  )
}

MobileTable.propTypes = {
  vouchers: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired
    })
  ).isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.func.isRequired,
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string,
      id: PropTypes.string.isRequired,
      minWidth: PropTypes.number
    })
  ).isRequired,
  activePanel: PropTypes.string,
  setActivePanel: PropTypes.func.isRequired
}

MobileTable.defaultProps = {
  devices: [],
  activePanel: ''
}
