import React from 'react'
import styles from '../payment.css'

const NoData = () => {
  return (
    <div className={styles.noData}>
      You have no saved cards on your account, add a card to complete purchases.
    </div>
  )
}

export default NoData
