import React from 'react'
import PropTypes from 'prop-types'
import {
  RecurringVouchers,
  AddVoucher
} from '../../../../containers/settings/my-account/subscription-and-payment/vouchers'
import { CardWrapper, CardContent } from '../../shared/card'
import styles from './index.css'

function Vouchers({
  recurringVouchers,
  renewalStatus,
  addVoucherVisible,
  message
}) {
  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        {addVoucherVisible ? (
          <AddVoucher />
        ) : (
          <p className={styles.information}>{message}</p>
        )}
        <RecurringVouchers
          recurringVouchers={recurringVouchers}
          renewalStatus={renewalStatus}
        />
      </CardContent>
    </CardWrapper>
  )
}

Vouchers.propTypes = {
  renewalStatus: PropTypes.string.isRequired,
  recurringVouchers: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
  addVoucherVisible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
}

export default Vouchers
