import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.css'
import { CardWrapper, CardContent, CardInnerHeading } from '../../shared/card'

function DcbSubscription({ dcbSubscriptionInfo }) {
  const header = `Billed through ${dcbSubscriptionInfo.partnerName}`
  const content = `Contact ${dcbSubscriptionInfo.partnerName} to update your payment details or end your billing through ${dcbSubscriptionInfo.partnerName}.`
  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        <CardInnerHeading header={header} subHeader={content} />
      </CardContent>
    </CardWrapper>
  )
}

DcbSubscription.propTypes = {
  dcbSubscriptionInfo: PropTypes.string.isRequired
}

export default DcbSubscription
