import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

import styles from '../payment.css'

import uncheckedIcon from '../../../../../../../images/checkbox-unchecked.svg'

const UncheckedIcon = ({ onClick }) => {
  return (
    <div className={styles.checkbox} onClick={onClick}>
      <ReactSVG
        src={uncheckedIcon}
      />
    </div>
  )
}

UncheckedIcon.propTypes = {
  onClick: PropTypes.func
}

UncheckedIcon.defaultProps = {
  onClick: () => {}
}

export default UncheckedIcon
