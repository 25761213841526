import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import { roundIcon } from '../../../../shared/round-icon.css'

import styles from '../payment.css'

import closeIcon from '../../../../../../../images/closeModal.svg'

const RemoveIcon = ({ onClick, isDefault }) => {
  return (
    <div>
      {
        isDefault
          ? (
            <div
              className={styles.removeButton}
            >
              <p>
                Can`t remove
                <br />
                {' '}
                a default
                <br />
                {' '}
                card
              </p>
            </div>
          )
          : (
            <div
              className={styles.removeButton}
              onClick={!isDefault && onClick}
            >
              <ReactSVG
                src={closeIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute('class', classNames(roundIcon, { [styles.disabled]: isDefault }))
                }}
              />
            </div>
          )
      }
    </div>
  )
}

RemoveIcon.propTypes = {
  onClick: PropTypes.func,
  isDefault: PropTypes.bool
}

RemoveIcon.defaultProps = {
  onClick: () => {},
  isDefault: false
}

export default RemoveIcon
