import React from 'react'
import PropTypes from 'prop-types'
import Media from 'react-media'
import { format, toDate } from 'date-fns'
import {
  propOr, match, isEmpty, filter, propEq, path, pathOr
} from 'ramda'
import classNames from 'classnames'
import { SCREEN_SIZE_BREAKPOINTS } from '../../../../../constants'
import styles from './recurring-vouchers.css'
import { CardInnerHeading } from '../../../shared/card'
import { DesktopTable } from './recurring-voucher-desktop-table-component'
import { MobileTable } from './recurring-voucher-mobile-table-component'

const getTableColumns = [
  {
    Header: 'Voucher Type',
    id: 'product',
    accessor: 'product',
    Cell: d => (
      <span className={styles.tableCell}>
        {d.value}
        {' '}
        Voucher
      </span>
    ),
    minWidth: 25
  },
  // TODO: hide voucher code and expiry date for now, because don't
  // have enough budget on asking evergent to change api (https://tdv-ott.atlassian.net/browse/LBX-967)
  // {
  //   Header: 'Voucher Code',
  //   id: 'code',
  //   accessor: 'code',
  //   Cell: d => <span className={styles.tableCell}>{d.value}</span>,
  //   minWidth: 25
  // },
  // {
  //   Header: 'Expiry Date',
  //   id: 'date',
  //   accessor: 'date',
  //   Cell: d => <span className={styles.tableCell}>{d.value}</span>,
  //   minWidth: 25
  // },
  {
    Header: 'Voucher Status',
    id: 'status',
    accessor: 'status',
    Cell: d => (
      <span className={classNames(styles.voucherStatus, styles.tableCell)}>
        {d.value}
      </span>
    ),
    minWidth: 25
  },
  {
    Header: 'Value',
    id: 'value',
    accessor: 'value',
    Cell: d => (
      <span className={styles.tableCell}>
        $
        {d.value}
      </span>
    ),
    minWidth: 25
  }
]

export const getVoucherType = promotionId => {
  if (!promotionId) return null

  if (!isEmpty(match(/VIP/, promotionId))) return 'VIP Voucher'
  if (!isEmpty(match(/SFT/, promotionId))) return 'Standard Free Trial'
  if (!isEmpty(match(/BFT/, promotionId))) return 'Basic Free Trial'
  if (!isEmpty(match(/SPARK/, promotionId))) return 'Spark Standard Voucher'

  const isPremium = !isEmpty(match(/PRE/, promotionId))
  const standardOrPremium = isPremium ? `Premium` : `Standard`

  const voucherType = `${standardOrPremium} Voucher`

  return voucherType
}

export const getExpiryDate = endDate => {
  if (!endDate) return null
  return format(toDate(parseInt(endDate, 10)), 'dd/MM/yyyy')
}

export const getVoucherStatus = (orderDetails) => {
  if (path(['promotion', 'isExhausted'], orderDetails)) {
    return 'Not in use'
  }

  return 'In use'
}

// as agreed with Product Owner, for MVP we will show only vouchers 'In use'.
// non-filtered vouchers array and getVoucherStatus() will be used in future
// after we confirm the logic for different voucher statuses with Evergent

export const vouchersInUse = vouchers => {
  return vouchers ? filter(propEq('status', 'In use'), vouchers) : []
}

const RecurringVouchers = ({
  recurringVouchers,
  handleTdProps,
  handleTheadThProps,
  handleTheadProps,
  handleTrGroupProps,
  handleTbodyProps,
  activePanel,
  setActivePanel
}) => {
  const vouchers = recurringVouchers.reduce((acc, voucher) => {
    const orderDetails = propOr({}, 'orderDetails', voucher)
    if (orderDetails && orderDetails.voucherCode) {
      const productName = isEmpty(match(/Premium/, orderDetails.productName)) ? orderDetails.productName : 'Standard'
      acc.push({
        product: productName,
        type: getVoucherType(path(['promotion', 'id'])(orderDetails)),
        code: orderDetails.voucherCode,
        date: path(['promotion', 'endDate'])(orderDetails),
        status: getVoucherStatus(orderDetails, recurringVouchers),
        value: path(['promotion', 'amount'])(orderDetails)
      })
    }

    return acc
  }, [])

  // TODO: currently only show voucher in use, will show all the related voucher in the future
  const voucherInUse = vouchersInUse(vouchers)
  const latestVoucher = voucherInUse.reduce((final, voucher) => {
    const finalDate = parseFloat(pathOr(0, ['date'])(final[0]))
    const voucherDate = parseFloat(pathOr(0, ['date'])(voucher))
    if (final.length === 0) final.push(voucher)
    if (finalDate >= voucherDate) {
      final.pop()
      final.push(voucher)
    }

    return final
  }, [])

  if (latestVoucher.length === 0) return null

  return (
    <>
      <CardInnerHeading
        header="Plan Vouchers"
        className={classNames(styles.CardInnerHeading, styles.voucherHeading)}
      />
      <Media query={`(min-width: ${SCREEN_SIZE_BREAKPOINTS.MIN_667}px)`}>
        {matches => {
          return (
            // `matches` comes from the `react-media` wrapper
            // to indicate whether our media query matches or not (true/false)
            matches ? (
              <DesktopTable
                tableColumns={getTableColumns}
                vouchers={latestVoucher}
                className={styles.voucherTable}
                handleTdProps={handleTdProps}
                handleTheadThProps={handleTheadThProps}
                handleTheadProps={handleTheadProps}
                handleTrGroupProps={handleTrGroupProps}
                handleTbodyProps={handleTbodyProps}
              />
            ) : (
              <MobileTable
                vouchers={latestVoucher}
                activePanel={activePanel}
                setActivePanel={setActivePanel}
                tableColumns={getTableColumns}
              />
            )
          )
        }}
      </Media>
    </>
  )
}

RecurringVouchers.propTypes = {
  recurringVouchers: PropTypes.arrayOf(
    PropTypes.shape({
      orderDetails: PropTypes.shape({
        status: PropTypes.string.isRequired,
        voucherCode: PropTypes.string,
        productName: PropTypes.string.isRequired,
        promotion: PropTypes.shape({
          endDate: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          amount: PropTypes.string.isRequired
        })
      }).isRequired
    })
  ).isRequired,
  handleTdProps: PropTypes.func.isRequired,
  handleTheadThProps: PropTypes.func.isRequired,
  handleTheadProps: PropTypes.func.isRequired,
  handleTrGroupProps: PropTypes.func.isRequired,
  handleTbodyProps: PropTypes.func.isRequired,
  activePanel: PropTypes.string,
  setActivePanel: PropTypes.func.isRequired
}

RecurringVouchers.defaultProps = {
  activePanel: null
}

export default RecurringVouchers
